.skills-container {
  background-color: rgba(0, 0, 0, 0.281);
  color: white;
  display: flex;
  justify-content: space-around;
  padding: 30px 30px 30px 30px;
  max-width: 1240px;
  margin: 0 auto;
}
.skills-outer-container {
  margin-top: 90px;
}

.header {
  color: white;
  background-color: transparent;
  margin: 0;
  text-align: center;
  font-size: 48px;
}
.skill-box div {
  font-size: 18px;
}
.skill-box {
  border: 2px solid #ffffff;
  margin: 10px;
  padding-top: 10px;
  padding-bottom: 50px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%; /* Adjust width as needed */
  text-align: center;
  background-color: transparent;
  /* background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  border-radius: 8px;
  transition: transform 0.5s ease-in-out;
}
.skill-box:hover {
  transform: scale(1.05); /* Make the boxes pop on hover */
  box-shadow: 0 0 7px rgb(255, 255, 255);
  background-color: black;
}
.header:hover {
  text-shadow: 0 0 5px rgb(255, 255, 255); /* Glow effect on hover */
}

.skill-box h3 {
  margin-top: 10px;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .skills-container {
    flex-direction: column; /* Stack boxes vertically on smaller screens */
    align-items: center;
  }
}
