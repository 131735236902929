.hclass {
  color: white;
  text-align: center;
  z-index: 1;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 30px;
  font-size: 48px;
  transition: all 0.5s ease;
}
.hclass:hover {
  text-shadow: 0 0 1px #ffffff4b, 0 0 5px #fff;
}
.aboutme-container {
  color: white;
  padding: 20px;
  padding-bottom: 30px;
  max-width: 1200px; /* Set the maximum width */
  margin: 0 auto; /* Center the container */
  position: relative; /* Ensure it's above the video */
  z-index: 1; /* Higher than video */
}

.section {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.section-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.2); /* Increase visibility */
  padding: 20px;
  border-radius: 10px;
  border: 2px solid white;
  transition: transform 0.5s ease, box-shadow 0.3s ease; /* Transition for pop and glow effect */
}

.section-content.reverse {
  flex-direction: row-reverse; /* Reverse the order of elements */
}

.section-content:hover {
  transform: scale(1.02); /* Slightly increase size */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Glow effect */
  background-color: black;
}

.text-container {
  flex: 1;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text-container h2 {
  margin: 0 0 10px 0;
  padding-left: 10px;
}

.text-container p {
  margin: 0;
  font-size: 18px;
  text-align: justify;
  padding: 10px;
}

.nd {
  text-align: right;
  padding-right: 10px;
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video {
  width: 190px;
  height: 200px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .section-content {
    flex-direction: column;
    background-color: transparent; /* Make background transparent on smaller screens */
    padding: 0; /* Remove padding on smaller screens */
  }

  .video {
    display: none; /* Hide videos on screens smaller than 768px */
  }

  .text-container {
    padding: 10px; /* Remove padding on smaller screens */
  }

  .nd {
    text-align: left;
  }

  .text-container h2 {
    padding-top: 5px;
  }
}
