/* Import Montserrat Font */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: black; /* Fallback color */
  color: white;
  font-family: "Montserrat", Arial, sans-serif; /* Set Montserrat as the primary font */
}

.app-container {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.video-background {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
  pointer-events: none; /* Allow interactions with other elements */
}

.content-container {
  position: relative;
  z-index: 1;
}

/* Custom Scrollbar */
.content {
  height: 200px; /* Adjust as needed */
  overflow: auto; /* Ensure scrollbar appears */
  padding: 10px;
  border: 1px solid #ddd;
}
