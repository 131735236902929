/* Footer.css */

.footer {
  background-color: #000000;
  padding: 20px;
  color: white;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  padding-right: 5px;
}

.footer-logo {
  height: 40px;
}

.footer-links {
  display: flex;
  gap: 30px;
}

.footer-links a {
  display: inline-block;
}

.footer-line {
  border: 0;
  height: 1px;
  background: white;
  margin: 10px 0;
}

.footer-credit {
  font-size: 15px;
  text-align: left;
}
.footer-icon {
  height: 40px;
  width: 40px;
  fill: white;
  transition: transform 0.3s;
}
.footer-icon:hover {
  transform: scale(1.2);
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .footer-icon {
    height: 30px; /* Adjust the size as needed */
    width: 30px; /* Adjust the size as needed */
  }
}
