/* Main navigation styles */
.main-nav {
  background-color: rgba(0, 0, 0, 0.849);
  padding: 15px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  transition: top 0.3s; /* Smooth transition for hiding/showing */
}

.main-nav.hide {
  top: -100px; /* Adjust this value based on your nav bar height */
}

/* Hamburger menu */
.hamburger {
  display: none;
  font-size: 24px;
  color: white;
  background: none;
  border: 2px solid white;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.5s;
}
.hamburger:hover {
  background-color: white;
  color: black;
  transform: scale(1.125); /* Slightly enlarge the button */
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.2);
}

/* Navigation items */
.nav-items {
  display: flex;
  justify-content: space-around;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 1200px;
  margin: 5px auto;
}

.nav-items li {
  flex: 1;
  display: flex;
  justify-content: center;
}

/* Button styles */
.nav-items button {
  background-color: transparent;
  color: white;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 8px;
  padding: 10px 30px;
  font-size: 20px;
  font-family: "Montserrat", Arial, sans-serif; /* Apply Montserrat font */
  cursor: pointer;
  width: 170px;
  text-align: center;
  box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
  transition: all 0.5s;
}

.nav-items button:hover {
  background-color: white;
  color: black;
  transform: scale(1.125); /* Slightly enlarge the button */
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.2); /* Add a shadow for a popping effect */
}

/* Responsive styles for smaller screens */
@media (max-width: 885px) {
  .hamburger {
    display: block;
  }
  .main-nav {
    background-color: black;
  }
  .nav-items {
    flex-direction: column;
    display: none;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    background-color: black;
  }

  .nav-items.open {
    display: flex;
  }

  .nav-items li {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }
}
