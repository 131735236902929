@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  25% {
    opacity: 1;
    transform: translateY(0);
  }
  75% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
}

.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
  background-color: transparent; /* Optional: Add a background color for visibility */
}

.head-container {
  font-size: 7rem; /* Make the text very large */
  text-align: center;
  color: #ffffff; /* Optional: Add text color */
}

.text-fade-up {
  animation: fadeUp 6s infinite;
}
@media (max-width: 768px) {
  .head-container {
    font-size: 4.5rem; /* Adjust the font size for smaller screens */
    /* Add any other adjustments needed for smaller screens */
  }
}
