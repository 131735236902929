.outerproj {
  margin-top: 100px;
  margin-bottom: 100px;
}
/* Projects container styles */
.projects-container {
  text-align: center;
  max-width: 1220px;
  color: white;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
  background-color: transparent; /* Semi-transparent background */
  position: relative;
  z-index: 2;
}
.myproj {
  font-size: 48px;
}
.myproj:hover {
  text-shadow: 0 0 1px #ffffff4b, 0 0 5px #fff;
}
/* Projects grid styles */
.projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

/* Button styles */
.buttonp {
  color: white;
  background-color: black;
  border: 2px solid white;
  font-size: 20px;
  padding: 10px 25px;
  margin-top: 20px;
  border-radius: 10px;
  transition: all 0.5s ease; /* Smooth transition for hover effect */
}

.buttonp:hover {
  color: black;
  background-color: white;
  transform: scale(1.08); /* Pop-up effect */
}

/* Project box styles */
.project-box {
  background-color: transparent;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease; /* Smooth transition for hover effect */
}

.project-box:hover {
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.8); /* Glow effect */
  transform: scale(1.025); /* Pop-up effect */
  background-color: black;
}

/* Project box heading styles */
.project-box h2 {
  margin: 0 0 10px 0;
}

/* Project box paragraph styles */
.project-box p {
  margin-top: 0;
  font-size: 18px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .projects-grid {
    grid-template-columns: 1fr; /* Align vertically on smaller screens */
  }
  .project-box {
    margin: 0px 30px;
  }
  .projects-container {
    padding-left: 0px;
    padding-right: 0px;
  }
}
